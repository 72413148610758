import pathnames from "routes/pathnames";

import AppLayout from "components/app-layout";
import PageHome from "pages/express-motor/page-home";
import PageEnquiry from "pages/express-motor/page-enquiry";
import PageQuotation from "pages/express-motor/page-quotation";
import PageApplicant from "pages/express-motor/page-applicant";
import PageSummary from "pages/express-motor/page-summary";
import PageReceipt from "pages/express-motor/page-receipt";
import PageAppStore from "pages/express-motor/page-app-store";
import PageMotorType from "pages/express-motor/page-motor-type";
import PageManualEnquiry from "pages/express-motor/page-manual-enquiry";
import PageManualEnquiryReceipt from "pages/express-motor/page-receipt-manual";
import { quotationRoutes } from "common/auth-routes";

const routes = [
	{
		path: pathnames.home,
		element: <PageHome />,
	},
	{
		path: pathnames.enquiry,
		element: <PageEnquiry />,
	},
	{
		path: pathnames.quotation,
		element: <PageQuotation />,
		loader: quotationRoutes,
	},
	{
		path: pathnames.applicant,
		element: <PageApplicant />,
		loader: quotationRoutes,
	},
	{
		path: pathnames.summary,
		element: <PageSummary />,
		loader: quotationRoutes,
	},
	{
		path: pathnames.receipt,
		element: <PageReceipt />,
	},
	{
		path: pathnames.appStore,
		element: <PageAppStore />,
	},
	{
		path: pathnames.motorType,
		element: <PageMotorType />,
	},
	{
		path: pathnames.manualEnquiry,
		element: <PageManualEnquiry />,
	},
	{
		path: pathnames.manualEnquiryReceipt,
		element: <PageManualEnquiryReceipt />,
	},
];

const enrichedRouters = routes.map(({ ...res }) => {
	return { ...res, element: <AppLayout>{res.element}</AppLayout> };
});

export default enrichedRouters;
