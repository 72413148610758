import React, { memo, useMemo } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";

import classNames from "common/class-names";

const AppButtonImage = (props) => {
	const className = useMemo(() => {
		return classNames({
			"app-button-image": true,
			"app-button-image--outline": props.outline || false,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.outline]);

	const onHandlePress = (event) => {
		if (props.onClick) props.onClick(event);
	};

	const debouncePress = debounce(onHandlePress, 500);

	return (
		<button className={className} type={props.type} disabled={props.disabled} onClick={debouncePress}>
			<img className="app-button-image__image" src={props.icon} alt="icon" />
			<p className="app-button-image__label">{props.label}</p>
			{props.descript && (
				<p className="app-button-image__descrip">{props.descript}</p>
			)}
		</button>
	);
};

AppButtonImage.propTypes = {
	onClick: PropTypes.func,
	outline: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	descript: PropTypes.string,
	type: PropTypes.oneOf(["button", "submit", "reset"]).isRequired,
	icon: PropTypes.string.isRequired,
};

export default memo(AppButtonImage);
