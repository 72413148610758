import { configureStore, combineReducers, MiddlewareArray } from "@reduxjs/toolkit";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import COMMON from "common";
import alertSlice from "store/slices/alert";
import motorSlice from "store/slices/motor";
import manualSlice from "store/slices/manual";

const persistConfig = {
	key: COMMON.REDUX_PRESIST_KEY,
	stateReconciler: autoMergeLevel2,
	blacklist: ["alert", "motor", "manual"],
	storage,
};

const dev = process.env.NODE_ENV === "development";
const middlewares = [];

if (dev) {
	const { createLogger } = require("redux-logger");
	const reduxLogger = createLogger({ collapsed: true });
	middlewares.push(reduxLogger);
}

const reducers = {
	alert: alertSlice,
	motor: motorSlice,
	manual: manualSlice,
};

const combinedReducers = combineReducers(reducers);
const rootReducer = (state, action) => {
	if (action.type === COMMON.REDUX_ACTION.LOGOUT) state = undefined;
	return combinedReducers(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
	reducer: persistedReducer,
	middleware: new MiddlewareArray().concat(middlewares),
});

export const persistor = persistStore(store);

export default store;
