import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import COMMON from "common";
import pathnames from "routes/pathnames";
import AppButton from "components/app-button";
import { resetMotor } from "store/slices/motor";
import tickIcon from "assets/images/green-checked-icon.svg";
import wrongIcon from "assets/images/pages/page-enquiry/wrong-icon.webp";
import calendarIcon from "assets/images/pages/page-enquiry/calendar-icon.svg";
import wrongInfoIcon from "assets/images/pages/page-applicant/wrong-info-icon.webp";
import paymentFailedIcon from "assets/images/pages/page-receipt/payment-failed-icon.webp";

const AppCardStatus = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onHandleNavigateHome = useCallback(() => {
		navigate(pathnames.home);
	}, [navigate]);

	const onHandleNavigateRetry = useCallback(() => {
		dispatch(resetMotor());
		props.setMessageType("");
	}, [dispatch, props]);

	const onHandleNavigateManualEnquiry = useCallback(() => {
		dispatch(resetMotor());
		sessionStorage.setItem('fromCardStatus', 'true');
		navigate(pathnames.manualEnquiry, {
			state: {type: "Car"} 
		});
	}, [dispatch, navigate]);

	const onHandleNavigateBackToPrev = useCallback(() => {
		props.setMessageType("");
	}, [props]);

	const onHandleNavigateEnquiry = useCallback(() => {
		dispatch(resetMotor());
		navigate(pathnames.enquiry);
	}, [navigate, dispatch]);

	switch (props.messageType) {
		case COMMON.MESSAGE_TYPE.RENEW:
			const errorList = JSON.parse(props?.error);

			return (
				<div className="app-card-status">
					<div className="card-status__body">
						<img className="card-status__calendar-icon" src={calendarIcon} alt="calendar" />

						<p className="card-status__title">You may renew as early as 2 months before the expiry date.</p>
						<p className="card-status__sub-title">Here are the details for your next renewal:</p>

						<div className="card-status__container">
							<div className="card-status__wrapper">
								<img src={tickIcon} alt="tick" />

								{/* prettier-ignore */}
								<p className="card-status__description">Your No Claim Discount (NCD): <b>{errorList.misc1}%</b></p>
							</div>

							<div className="card-status__wrapper">
								<img src={tickIcon} alt="tick" />

								{/* prettier-ignore */}
								<p className="card-status__description">Your certificate will expire on: <b>{errorList.misc2}</b></p>
							</div>

							<p className="card-status__sub-description">*Applicable only if there were no claims made during the issuance of the certificate.</p>
						</div>

						<div className="card-status__button-container">
							<AppButton type="button" label="Back to home" onClick={onHandleNavigateHome} />
						</div>
					</div>
				</div>
			);
		case COMMON.MESSAGE_TYPE.INVALID:
			return (
				<div className="app-card-status">
					<div className="card-status__body">
						<img className="card-status__wrong-icon" src={wrongIcon} alt="wrong" />

						<p className="card-status__title">Something is wrong</p>
						<p className="card-status__sub-title">We are unable to proceed with the quotation as an error occurred when retrieving invalid information.</p>
						<p className="card-status__note">Note: Kindly contact our support team at <a href="mailto:customerservice@easycover.my" className="card-status__email">customerservice@easycover.my</a> for further assistance.</p>
						{props?.error && <p className="card-status__error">{props.error}</p>}

						<div className="card-status__button-container">
							<AppButton type="button" label="Retry" onClick={onHandleNavigateRetry} />
							<AppButton type="button" label="Manual Quotation" onClick={onHandleNavigateManualEnquiry} />
						</div>

						<AppButton type="button" outline label="Back to home" onClick={onHandleNavigateHome} />
					</div>
				</div>
			);
		case COMMON.MESSAGE_TYPE.NETWORK:
			return (
				<div className="app-card-status">
					<div className="card-status__network-body">
						<p className="card-status__network-title">No Connection Found</p>
						<p className="card-status__sub-title">Please check your internet connection.</p>
						<p className="card-status__sub-title">you are in offline now.</p>

						<div className="card-status__button-container">
							<AppButton type="button" outline label="Back to home" onClick={onHandleNavigateHome} />
							<AppButton type="button" label="Retry" onClick={onHandleNavigateRetry} />
						</div>
					</div>
				</div>
			);
		case COMMON.MESSAGE_TYPE.PAYMENT_FAILED:
			return (
				<div className="app-card-status">
					<div className="card-status__body">
						<img className="card-status__fail-icon" src={paymentFailedIcon} alt="wrong" />

						<p className="card-status__title">Your payment has failed</p>
						<p className="card-status__sub-title">Something went wrong.</p>
						<p className="card-status__sub-title">Don’t worries! Let’s try again</p>
						<div className="card-status__button-container">
							<AppButton type="button" label="Retry" onClick={onHandleNavigateEnquiry} />
						</div>
					</div>
				</div>
			);
		case COMMON.MESSAGE_TYPE.APPLICANT_FAILED:
			return (
				<div className="app-card-status">
					<div className="card-status__body">
						<img className="card-status__applicant-icon" src={wrongInfoIcon} alt="applicantFailed" />

						<p className="card-status__title">Something went wrong.</p>
						<p className="card-status__sub-title">We are unable to proceed with invalid information as an error occurred when retrieving invalid information.</p>
						<div className="card-status__button-container">
							<AppButton type="button" outline label="Back to home" onClick={onHandleNavigateHome} />
							<AppButton type="button" label="Retry" onClick={onHandleNavigateBackToPrev} />
						</div>
					</div>
				</div>
			);
			case COMMON.MESSAGE_TYPE.BAD_RESPONSE:
				return (
					<div className="app-card-status">
						<div className="card-status__body">
							<img className="card-status__wrong-icon" src={wrongIcon} alt="wrong" />
	
							<p className="card-status__title">Something is wrong</p>
							<p className="card-status__sub-title">We are unable to proceed with the quotation as an error occurred with bad request.</p>
							<p className="card-status__note">Note: Kindly contact our support team at <a href="mailto:customerservice@easycover.my" className="card-status__email">customerservice@easycover.my</a> for further assistance.</p>
							{props?.error && <p className="card-status__error">{props.error}</p>}
	
							<div className="card-status__button-container">
								<AppButton type="button" label="Retry" onClick={onHandleNavigateRetry} />
								<AppButton type="button" outline label="Back to home" onClick={onHandleNavigateHome} />
							</div>
						</div>
					</div>
				);
		default:
			return null;
	}
};

AppCardStatus.propTypes = {
	error: PropTypes.string,
	setMessageType: PropTypes.func,
	messageType: PropTypes.string.isRequired,
};

export default memo(AppCardStatus);
