import React, { useMemo, useCallback, useEffect, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { setReceipt } from "store/slices/motor";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppButton from "components/app-button";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import step1Icon from "assets/images/pages/page-receipt/step1-icon.webp";
import step2Icon from "assets/images/pages/page-receipt/step2-icon.webp";
import step3Icon from "assets/images/pages/page-receipt/step3-icon.webp";
import step4Icon from "assets/images/pages/page-receipt/step4-icon.webp";
import paymentSuccessIcon from "assets/images/pages/page-receipt/payment-success-icon.webp";
import appleAppStoreIcon from "assets/images/pages/page-app-store/apple-app-store-icon.svg";
import googleAppStoreIcon from "assets/images/pages/page-app-store/google-app-store-icon.svg";
import huaweiAppStoreIcon from "assets/images/pages/page-app-store/huawei-app-store-icon.svg";

const PageReceipt = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const motor = useSelector((state) => state.motor);
	const receiptInfo = useMemo(() => motor?.receipt, [motor?.receipt]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const [messageType, setMessageType] = useState("");

	const onHandleNavigateHome = () => {
		navigate(pathnames.home);
	};

	const onHandleNavigateDownload = useCallback(() => {
		navigate(pathnames.appStore);
	}, [navigate]);

	const onHandleNavigateAppIos = () => {
		window.open(process.env.REACT_APP_APP_STORE);
	};

	const onHandleNavigateAppAndroid = () => {
		window.open(process.env.REACT_APP_PLAY_STORE);
	};

	const onHandleNavigateAppHuawei = () => {
		window.open(process.env.REACT_APP_GALLERY);
	};

	//prettier-ignore
	const totalReceiptData = useMemo(() => ({
        order:[
            { label: "Reference Code", value: receiptInfo?.paymentReferenceCode, bold: true },
            { label: "Payment Date", value: formatDatePattern(receiptInfo?.paymentDate ? new Date(receiptInfo?.paymentDate) : ""), bold: true },
        ],
		info: [
			{ label: "Selected Insurer", value: receiptInfo?.selectedInsurer },
			{ label: "Value Type", value: receiptInfo?.valueType },
			{ label: "Sum Insured", value: `RM${formatCurrency(receiptInfo?.sumInsured)}`, bold: true },
		],
        insurancePremium:[
			{ label: "Insurance Premium", value: "", bold: true },
			{ label: "Gross Premium", value: `RM${formatCurrency(receiptInfo?.grossPremium)}`, bold: true },
			{ label: "SST (8%)", value: `RM${formatCurrency(receiptInfo?.sst)}` },
			{ label: "Stamp Duty Fee", value: `RM${formatCurrency(receiptInfo?.stampDutyFee)}` },
        ],
        totalPayable: [
            { label: "Total Payable:", value: `RM${formatCurrency(receiptInfo?.totalPayable)}`, bold: true }
		]
	}), [receiptInfo]);

	const className = useCallback((obj) => {
		return classNames({
			"quotation-list__text": true,
			"quotation-list__description": obj?.bold,
		});
	}, []);

	const valueClassName = useCallback((obj) => {
		return classNames({
			"quotation-list__text": !obj?.bold,
			"quotation-list__text--value": !obj?.bold,
			"quotation-list__description": obj?.bold,
			"quotation-list__description--value": obj?.bold,
		});
	}, []);

	const headerClassName = useCallback((obj) => {
		return classNames({
			"quotation-list__description": true,
			"quotation-list__description--value": obj?.bold,
		});
	}, []);

	const onHandleCheckPaymentStatus = useCallback(async () => {
		let response = null;

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const quotationId = urlParams.get("requestId");

		try {
			let payload = {
				quotationId: quotationId,
			};

			response = await api.get.motor.paymentStatus(payload);

			if (response.status === COMMON.MESSAGE_TYPE.SUCCEEDED) {
				let data = {
					grossPremium: response.grossPremium,
					paymentDate: response.paymentDate,
					paymentReferenceCode: response.paymentReferenceCode,
					selectedInsurer: response.selectedInsurer,
					sst: response.sst,
					stampDutyFee: response.stampDutyFee,
					status: response.status,
					sumInsured: response.sumInsured,
					totalPayable: response.totalPayable,
					valueType: response.valueType
				};

				dispatch(setReceipt(data));
			} else {
				setMessageType(COMMON.MESSAGE_TYPE.PAYMENT_FAILED);
			}
		} catch (error) {
			switch (error?.response?.data?.errorCode) {
				case COMMON.ERRORS.PAYMENT_QUOTATION_INVALID:
				case COMMON.ERRORS.PAYMENT_EXIST:
					setMessageType(COMMON.MESSAGE_TYPE.PAYMENT_FAILED);
					break;
				default:
					serveRequestErrors(error);
					break;
			}
		}
	}, [dispatch]);

	useEffect(() => {
		onHandleCheckPaymentStatus();
	}, [onHandleCheckPaymentStatus]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.INSURER.PAYMENT_STATUS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-receipt">
			<div className="receipt">
				{!messageType && (
					<Fragment>
						<div className="receipt__body">
							<img className="receipt__icon" src={paymentSuccessIcon} alt="payment success" />
							<p className="receipt__status">Payment Successful!</p>

							<div className="receipt__container">
								<div className="receipt__wrapper">
									<p className="receipt__title">Payment Summary</p>
								</div>

								<div className="receipt__wrapper">
									<div className="receipt-form__container">
										<div className="receipt-form__header">
											{totalReceiptData.order.map((o) => {
												return (
													<ul className="header-quotation-list" key={o.label}>
														<li className="header-quotation-list__item">
															<p className={className()}>{o.label}</p>
															<p className={headerClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
													</ul>
												);
											})}
										</div>

										<div className="receipt-form__divider" />

										<div className="receipt-form__body">
											<div className="receipt-form__wrapper">
												{totalReceiptData.info.map((o) => {
													return (
														<ul className="quotation-list" key={o.label}>
															<li className="quotation-list__item">
																<p className={className({ bold: o.bold })}>{o.label}</p>
																<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
															</li>
														</ul>
													);
												})}
											</div>

											<div className="receipt-form__wrapper">
												{totalReceiptData.insurancePremium.map((o) => {
													return (
														<ul className="quotation-list" key={o.label}>
															<li className="quotation-list__item">
																<p className={className({ bold: o.bold })}>{o.label}</p>
																<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
															</li>
														</ul>
													);
												})}
											</div>
										</div>

										<div className="receipt-form__divider" />

										<div className="receipt-form__payable-amount">
											{totalReceiptData.totalPayable.map((o) => {
												return (
													<ul className="quotation-list" key={o.label}>
														<li className="quotation-list__item">
															<p className={className({ bold: o.bold })}>{o.label}</p>
															<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
													</ul>
												);
											})}
										</div>

										<div className="receipt-form__divider" />

										<div className="receipt-form__footer">
											<p className="receipt-form__description">Thank you for insuring with easycover.</p>
											<p className="receipt-form__description">An covernote / policy document will be sent to your email within 24 hours. </p>
										</div>
									</div>
								</div>
							</div>

							<div className="step__body">
								<div className="step__wrapper">
									<p className="step__text">Download our mobile app to view policy details. To get started, please follow these simple steps:</p>
								</div>

								<div className="step__wrapper">
									<div className="step__box">
										<p className="step__title">Step 1</p>

										<div className="step__content">
											<img src={step1Icon} alt="step1" />
											<p className="step__text">Download ‘easycover.my’ app from App Store, Google Play, App Gallery or click the link to download the app.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 2</p>

										<div className="step__content">
											<img src={step2Icon} alt="step2" />
											<p className="step__text">Login in or create an account using the same email address that purchase the insurance plan.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 3</p>

										<div className="step__content">
											<img src={step3Icon} alt="step3" />
											<p className="step__text">Tap on the My Coverage.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 4</p>

										<div className="step__content">
											<img src={step4Icon} alt="step4" />
											<p className="step__text">Tap to view policy details.</p>
										</div>
									</div>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 1: Download the Mobile App</p>

									<div className="step__button-container">
										<button type="button" className="step__button" onClick={onHandleNavigateAppIos}>
											<img src={appleAppStoreIcon} alt="apple" />
										</button>

										<button type="button" className="step__button" onClick={onHandleNavigateAppAndroid}>
											<img src={googleAppStoreIcon} alt="google" />
										</button>

										<button type="button" className="step__button" onClick={onHandleNavigateAppHuawei}>
											<img src={huaweiAppStoreIcon} alt="huawei" />
										</button>
									</div>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 2: Create your account</p>
									<p className="step__sub-text">Once the app is installed, open it and tap on “Login/Sign up” and tap on “Sign up” on pop up screen.</p>

									<ul className="step-list">
										<li className="step-list__item">Please sign up with your personal email address and relevant details.</li>
										<li className="step-list__item">Complete the verification process to confirm your account.</li>
									</ul>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 3: Explore Your Benefits</p>
									<p className="step__sub-text">
										Congratulations! You are now set up to enjoy the full range of company benefits, including insurance coverage. Inside the app, you can easily view your insurance policy details, coverage limits, claim
										procedures, and more.
									</p>
								</div>
							</div>
						</div>

						<div className="receipt__button-container">
							<AppButton outline type="button" label="Download app" onClick={onHandleNavigateDownload} />
							<AppButton type="button" label="Back to home" onClick={onHandleNavigateHome} />
						</div>
					</Fragment>
				)}
				{messageType && (
					<div className="receipt__body">
						<AppCardStatus messageType={messageType} />
					</div>
				)}
			</div>
		</div>
	);
};

export default PageReceipt;
